<template>
  <div>
    <!-- IF FOUND ORDER -->
    <template v-if="!notFound">
      <div class="text-center" v-if="loader && !order">
        <Loader
          :size="'big'"
        />
      </div>
      <div v-else-if="order">
        <div class="mb-4">
          <h2 class="font-size-large highlighted-color1">
            <span>Ordem de pagamento #{{ order.ref_id }}</span>
          </h2>
        </div>
        <!-- ADVERTISER CARD -->
        <div class="card mb-4">
          <div class="card-body d-lg-flex align-items-lg-start justify-content-lg-between">
            <div>
              <h3 class="font-size-medium">{{ order.advertiser.name }}</h3>
              <h4 class="font-size-regular mb-0">Valor: {{ order.amount / 100 | formatMoney }}</h4>
            </div>
            <div v-if="user">
              <router-link :to="{ name: 'campaigns.index' }" class="btn btn-color1">Ver campanhas</router-link>
            </div>
          </div>
        </div> <!-- END ADVERTISER CARD -->
        <!-- PAYMENT DATA -->
        <div class="col-lg-7 mx-auto">
          <!-- INVOICE DATA -->
          <template v-if="invoice">
            <div class="text-center mb-3">
              <h3 class="font-size-medium highlighted-color1">
                <span>Situação do seu pagamento</span>
              </h3>
              <p>
                <strong>Atenção:</strong> Você pode sempre acessar essa página para ver o status do seu pagamento de maneira atualizada.
              </p>
            </div>
            <div class="card mb-4">
              <div class="card-body">
                <p>
                  <strong>Forma de pagamento:</strong> {{ invoice.payment_method | formatPaymentMethod }}<br/>
                  <strong>Status:</strong> {{ invoice.payment_status | formatPaymentStatus }}
                </p>
                <template v-if="invoice.payment_method === 'BOLETO' && invoice.payment_status !== 'PAID'">
                  <p>
                    <strong>Código de barras:</strong> {{ invoice.boleto_barcode }}<br/>
                    <strong>Data de vencimento:</strong> {{ invoice.boleto_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
                    <strong>Link para impressão do boleto:</strong> <a :href="invoice.boleto_url" target="_blank">{{ invoice.boleto_url }}</a>
                  </p>
                </template>
                <template v-else-if="invoice.payment_method === 'PIX'">
                  <p><strong>Data de vencimento:</strong> {{ invoice.pix_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</p>
                  <PIXQRCodeCard
                    :code="invoice.pix_qr_code"
                  />
                </template>
                <template v-else-if="invoice.payment_method === 'CREDIT_CARD'">
                  <p>
                    <strong>Bandeira do cartão:</strong> {{ invoice.card_brand | formatCreditCardBrand }}<br/>
                    <strong>Cartão de final:</strong> {{ invoice.card_last_digits }}
                  </p>
                </template>
                <p>
                  <strong>Dados da nota fiscal:</strong><br/>
                  <strong>Nome:</strong> {{ billingProfileEntity.name }}<br/>
                  <strong>CPF/CNPJ:</strong> {{ billingProfileEntity.document | VMask(documentMask) }}<br/>
                  <strong>Endereço:</strong> {{ `${billingProfileEntity.address.street}, ${billingProfileEntity.address.number} - ${billingProfileEntity.address.city}, ${billingProfileEntity.address.state}` }}
                </p>
              </div>
            </div>
          </template> <!-- END INVOICE DATA -->
          <!-- GENERATE PAYMENT WRAP -->
          <template v-if="!invoice || order.payment_status === 'REFUSED'">
            <div class="text-center mb-3">
              <h3 class="font-size-medium highlighted-color1">
                <span>Dados para emissão da nota fiscal</span>
              </h3>
            </div>
            <div class="col-12 col-md-6 mx-auto mb-3 default-form">
              <div class="form-group w-100">
                  <label class="form-label fw-bold">
                    Perfil de cobrança*
                    <span v-b-tooltip.hover :title="tooltips.billing_profile"><i class="fa fa-info-circle"></i></span>
                  </label>
                  <div class="custom-select">
                    <select class="form-control" v-model.trim="form.billing_profile">
                      <option value="ADVERTISER">Anunciante</option>
                      <option value="COMPANY">Empresa</option>
                    </select>
                  </div>
                </div>
            </div>
            <div class="col-12 col-mg-8 mx-auto">
              <p>
                <strong>Nome:</strong> {{ billingProfileEntity.name }}<br/>
                <strong>CPF/CNPJ:</strong> {{ billingProfileEntity.document | VMask(documentMask) }}<br/>
                <strong>Endereço:</strong> {{ `${billingProfileEntity.address.street}, ${billingProfileEntity.address.number} - ${billingProfileEntity.address.city}, ${billingProfileEntity.address.state}` }}
              </p>
            </div>

            <div class="text-center mb-3">
              <h3 class="font-size-medium highlighted-color1">
                <span>Selecione um método de pagamento</span>
              </h3>
            </div>
            <!-- BUTTONS -->
            <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">
              <Button
                type="button"
                class="btn mb-3 mb-md-0 me-md-3 text-nowrap"
                :class="{
                  'btn-primary': form.payment_method !== 'BOLETO',
                  'btn-color1': form.payment_method === 'BOLETO'
                }"
                @click="changePaymentMethod('BOLETO')"
              >
                Boleto bancário
              </Button>
              <Button
                type="button"
                class="btn mb-3 mb-md-0 me-md-3 text-nowrap"
                :class="{
                  'btn-primary': form.payment_method !== 'CREDIT_CARD',
                  'btn-color1': form.payment_method === 'CREDIT_CARD'
                }"
                @click="changePaymentMethod('CREDIT_CARD')"
              >
                Cartão de crédito
              </Button>
              <Button
                type="button"
                class="btn text-nowrap"
                :class="{
                  'btn-primary': form.payment_method !== 'PIX',
                  'btn-color1': form.payment_method === 'PIX'
                }"
                @click="changePaymentMethod('PIX')"
              >
                PIX
              </Button>
            </div> <!-- END BUTTONS -->
            <!-- FORM -->
            <form @submit.prevent="processForm" class="default-form">
              <AlertWraper
                class="mt-4"
                :content="errors"
                :type="'error'"
              />
              <!-- CREDIT CARD FORM -->
              <div class="mt-4" v-if="form.payment_method === 'CREDIT_CARD'">
                <h3 class="font-size-medium highlighted-color1">
                  <span>Dados do cartão</span>
                </h3>
                <div class="mt-4 mb-4">
                  <div
                    class="d-lg-flex mb-3"
                  >
                    <div class="col-lg-6 pl-0">
                      <div class="form-group pe-3">
                        <label class="form-label fw-bold">Número do cartão</label>
                        <TheMask
                          :mask="'#### #### #### ####'"
                          v-model.trim="$v.form.card.number.$model"
                          placeholder=""
                          class="form-control"
                          type="tel"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 pl-0 pe-3">
                      <label class="form-label fw-bold">Nome do portador</label>
                      <input type="text" class="form-control" v-model.trim="$v.form.card.holder_name.$model" placeholder="" />
                    </div>
                  </div>
                  <div
                    class="d-lg-flex"
                  >
                    <div class="col-lg-6 pl-0 pe-3">
                      <label class="form-label fw-bold">Data de validade</label>
                      <div class="form-group">
                        <TheMask
                          :mask="'##/##'"
                          v-model="$v.form.card.expiration_date.$model"
                          placeholder="MM/AA"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 px-0 pe-3">
                      <div class="form-group">
                        <label class="form-label fw-bold">CVV</label>
                        <input type="text" class="form-control" v-model.trim="$v.form.card.cvv.$model" placeholder="" />
                      </div>
                    </div>
                  </div>
                </div>
                <h3 class="font-size-medium highlighted-color1">
                  <span>Endereço de cobrança do cartão</span>
                </h3>
                <div class="mb-3">
                  <div class="position-relative">
                    <LoaderOverlay
                      size="big"
                      v-if="zipcodeLoader"
                    />
                    <div
                      class="d-lg-flex mb-3"
                    >
                      <div class="col-lg-4 pl-0">
                        <div class="form-group">
                          <label class="form-label fw-bold">CEP</label>
                          <TheMask
                            :mask="'#####-###'"
                            v-model.trim="$v.form.address.zipcode.$model"
                            placeholder=""
                            class="form-control"
                            @change.native="getZipcode"
                          />
                        </div>
                      </div>
                      <div class="col-lg-8 px-0 ps-3">
                        <div class="form-group">
                          <label class="form-label fw-bold">Logradouro</label>
                          <input type="text" class="form-control" v-model.trim="$v.form.address.street.$model" placeholder="" :readonly="readonlyForm" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-lg-flex mb-3"
                    >
                      <div class="col-lg-4 pl-0">
                        <div class="form-group">
                          <label class="form-label fw-bold">Número</label>
                          <input type="text" class="form-control" v-model.trim="$v.form.address.number.$model" placeholder="" />
                        </div>
                      </div>
                      <div class="col-lg-4 pl-0 px-3">
                        <div class="form-group">
                          <label class="form-label fw-bold">Complemento</label>
                          <input type="text" class="form-control" v-model.trim="form.address.complement" placeholder="" />
                        </div>
                      </div>
                      <div class="col-lg-4 px-0">
                        <div class="form-group">
                          <label class="form-label fw-bold">Bairro</label>
                          <input type="text" class="form-control" v-model.trim="$v.form.address.neighborhood.$model" placeholder="" :readonly="readonlyForm" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-lg-flex"
                    >
                      <div class="col-lg-5 pl-0 pe-3">
                        <div class="form-group">
                          <label class="form-label fw-bold">Cidade</label>
                          <input type="text" class="form-control" v-model.trim="$v.form.address.city.$model" placeholder="" readonly />
                        </div>
                      </div>
                      <div class="col-lg-5 px-0 pe-3">
                        <div class="form-group">
                          <label class="form-label fw-bold">Estado</label>
                          <input type="text" class="form-control" v-model.trim="$v.form.address.state.$model" placeholder="" readonly />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- END CREDIT CARD FORM -->
              <div class="mt-5 text-center">
                <Button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="$v.$invalid"
                  :loading="loader"
                  v-if="form.payment_method"
                >
                  {{ paymentButtonText }}
                </Button>
                <p class="mt-4 font-size-large">
                  <i class="fa fa-lock me-3"></i> Pagamento 100% seguro
                </p>
              </div>
            </form><!-- END FORM -->
          </template> <!-- END GENERATE PAYMENT WRAP -->
        </div> <!-- END PAYMENT DATA -->
      </div>
    </template> <!-- END IF FOUND ORDER -->
    <!-- ORDER NOT FOUND -->
    <template v-else></template> <!-- END ORDER NOT FOUND -->
  </div>
</template>

<script>

import OrdersService from '@/modules/orders/services/orders-service'
import { getZipcodeData, parseErrorResponseToArray } from '@/utils'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import variables from '@/mixins/variables'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import PIXQRCodeCard from '@/components/common/QRCode/PIXQRCodeCard'
import { TheMask, mask } from 'vue-the-mask'

export default {
  mixins: [variables],
  components: {
    AlertWraper,
    Button,
    Loader,
    LoaderOverlay,
    PIXQRCodeCard,
    TheMask
  },
  directives: { mask },
  data () {
    return {
      errors: null,
      form: {
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        },
        billing_profile: '',
        card: {
          holder_name: '',
          expiration_date: '',
          number: '',
          cvv: ''
        },
        card_hash: '',
        payment_method: ''
      },
      invoice: null,
      isPagarmeLoaded: false,
      loader: false,
      notFound: false,
      order: null,
      readonlyForm: true,
      zipcodeLoader: false
    }
  },
  metaInfo: {
    title: 'Realizar pagamento'
  },
  validations () {
    const obj = {
      form: {
        payment_method: { required }
      }
    }
    if (this.form.payment_method === 'CREDIT_CARD') {
      obj.form.address = {
        zipcode: { required },
        street: { required },
        number: { required },
        neighborhood: { required },
        city: { required },
        state: { required }
      }
      obj.form.card = {
        holder_name: { required },
        expiration_date: { required },
        number: { required },
        cvv: { required }
      }
    }
    return obj
  },
  created () {
    this.getOrder()
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Billing profile entity
     */
    billingProfileEntity () {
      return this.form.billing_profile === 'ADVERTISER' ? this.order.advertiser : this.order.company
    },
    /**
     * Document mask
     */
    documentMask () {
      if (this.billingProfileEntity.document.length === 11) return '###.###.###-##'
      return '##.###.###/####-##'
    },
    /**
     * Payment button text
     */
    paymentButtonText () {
      switch (this.form.payment_method) {
        case 'BOLETO':
          return 'Gerar boleto bancário'
        case 'CREDIT_CARD':
          return 'Pagar com cartão'
        case 'PIX':
          return 'Gerar código PIX'
      }
      return ''
    }
  },
  methods: {
    /**
     * Change payment method
     */
    changePaymentMethod (method) {
      this.form.payment_method = method

      // Add pagarme script
      if (method === 'CREDIT_CARD' && !this.isPagarmeLoaded) {
        const pagarmeScript = document.createElement('script')
        pagarmeScript.src = 'https://assets.pagar.me/pagarme-js/4.11/pagarme.min.js'
        pagarmeScript.async = true
        document.body.appendChild(pagarmeScript)

        this.isPagarmeLoaded = true
      }
    },
    /**
     * Get order
     */
    async getOrder () {
      this.loader = true
      try {
        const order = await OrdersService.getOrder(this.$route.params.id, true)
        this.order = order
        if (order.invoices.length > 0) {
          this.invoice = order.invoices[order.invoices.length - 1]
          this.form.billing_profile = this.invoice.billing_profile
        } else {
          // Set billing profile
          this.form.billing_profile = order.advertiser.billing_profile
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Get zipcode data given a zipcode
     */
    async getZipcode () {
      this.zipcodeLoader = true
      this.content = null
      try {
        const address = await getZipcodeData(this.form.address.zipcode)
        this.$v.form.address.street.$model = address.street
        this.$v.form.address.neighborhood.$model = address.neighborhood
        this.$v.form.address.city.$model = address.city
        this.$v.form.address.state.$model = address.state
        this.readonlyForm = !!address.street
      } catch (error) {
        this.errors = parseErrorResponseToArray(error)
      }
      this.zipcodeLoader = false
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      this.errors = null
      try {
        const form = Object.assign({}, this.form)
        delete form.card
        const invoice = await OrdersService.createPayment(this.order.id, form)
        this.invoice = invoice
      } catch (error) {
        this.errors = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    async processForm () {
      this.loader = true
      // Validate card first
      if (this.form.payment_method === 'CREDIT_CARD') {
        const card = {
          card_holder_name: this.form.card.holder_name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
          card_expiration_date: this.form.card.expiration_date,
          card_number: this.form.card.number,
          card_cvv: this.form.card.cvv
        }

        // Validate card
        const validations = window.pagarme.validate({ card: card })
        const errors = []
        if (!validations.card.card_cvv) errors.push('Verifique o CVV digitado')
        if (!validations.card.card_expiration_date) errors.push('Verifique a data de validade digitada')
        if (!validations.card.card_holder_name) errors.push('Verifique o nome do portador digitado')
        if (!validations.card.card_number) errors.push('Verifique o número do cartão digitado')

        if (errors.length > 0) {
          this.loader = false
          this.errors = errors
          return
        }
        // Generate hash
        window.pagarme.client.connect({ encryption_key: process.env.VUE_APP_PAGARME_ENCRYPTION_KEY })
          .then(client => client.security.encrypt(card))
          // eslint-disable-next-line camelcase
          .then(card_hash => {
            // eslint-disable-next-line camelcase
            this.form.card_hash = card_hash
            this.submit()
          })
          .catch(() => {
            this.loader = false
            this.errors = ['Erro ao processar cartão, tente novamente mais tarde']
          })
      } else {
        this.submit()
      }
    }
  }
}
</script>
